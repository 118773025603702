import React from "react";
import RightSidebar from "./../../postInfo/rightSidebar/RightSidebar";
import PageHeader from "./../../header/PageHeader";
import ContentPage from "./../../postInfo/contentPage/ContentPage";
import MainPage from "./../../postInfo/contentPage/MainPage";
import { useParams } from "react-router-dom";
import useFetch, { getApiUrl } from "../../useFetch/useFetch";
import PostSlider from "../../postInfo/postSlider/PostSlider";
import PostContent from "../../postInfo/postContent/PostContent";
import LoadingModalWithTimer from "../../website/LoadingModalWithTimer";
import PostTitle from "../../postInfo/PostTitle";
import IsNotAnyThing from "../../website/IsNotAnyThing";
import PostAuthorInfo from "../../postInfo/PostAuthorInfo";

function SingleBlog() {
    const { id } = useParams();
    const { data } = useFetch(`blog/` + id);
    let categories = null;
    let blog = null;

    let blogTitle = "Blog Title";

    if (data && data.categories) {
        categories = data.categories;
    }

    if (data && data.blog) {
        blog = data.blog;
        blogTitle = blog.title;
    }

    const blogFiles =
        data && data.files
            ? data.files.map((file) => getApiUrl() + file.file_url)
            : [];

    return (
        <>
            <LoadingModalWithTimer timer={1000} />
            <PageHeader title={`Blogs/${blogTitle}`} />
            <ContentPage>
                <MainPage>
                    {data && data.blog !== null ? (
                        <>
                            <PostSlider images={blogFiles} />

                            <div className="mt-3">
                                <PostTitle
                                    titleName={blogTitle}
                                    fontSize={30}
                                />

                                <PostAuthorInfo created_at={blog.created_at} />
                            </div>

                            {blog && <PostContent content={blog.description} />}
                        </>
                    ) : (
                        <IsNotAnyThing />
                    )}
                </MainPage>
                <RightSidebar categories={categories} categoryType={"blogs"} />
            </ContentPage>
        </>
    );
}

export default SingleBlog;
