import React from "react";

function SliderImage(props) {
    const imageSrc = props.imageSrc;
    return (
        <div className="carousel-image-container">
            <img className="carousel-image" src={imageSrc} alt="sliderImage" />
        </div>
    );
}
export default SliderImage;
