import React from "react";

function MainPage({ children, columnSize = 9 }) {
    return (
        <main id="main" className={`col-md-${columnSize}`}>
            <div className="row">{children}</div>
        </main>
    );
}
export default MainPage;
