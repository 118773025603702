import Carousel from "react-bootstrap/Carousel";
import SliderItem from "./SliderItem";
import "./Slider.css";
import SliderImage from "./SliderImage";
import useFetch, {
    generateUniqueID,
    getApiUrl,
} from "./../../useFetch/useFetch";

function Slider() {
    const { data: sliders } = useFetch("get-sliders");

    return (
        <Carousel>
            {sliders &&
                sliders.map((slider) => (
                    <Carousel.Item key={generateUniqueID()} interval={1000}>
                        <SliderImage
                            key={generateUniqueID()}
                            imageSrc={
                                getApiUrl() +
                                slider.get_slider_bg_image.file_url
                            }
                        />
                        <SliderItem
                            key={generateUniqueID()}
                            captionTitle={slider.title}
                            captionText={slider.description}
                            button_url={slider.button_url}
                        />
                    </Carousel.Item>
                ))}
        </Carousel>
    );
}

export default Slider;
