import React from "react";
import FooterBottom from "./bottom/FooterBottom";
import FirstColumn from "./firstColumn/FirstColumn";
import SecondColumn from "./secondColumn/SecondColumn";
import ThirdColumn from "./thirdColumn/ThirdColumn";

function Footer() {
    return (
        <footer id="footer" className="section">
            <div className="container">
                <div className="row">
                    <FirstColumn />
                    <SecondColumn />
                    <ThirdColumn />
                </div>
                <FooterBottom />
            </div>
        </footer>
    );
}
export default Footer;
