import React from "react";
import ListItem from "../../tags/ul/ListItem";
import Italic from "../../tags/Italic";
import { Link } from "react-router-dom";

function SocialMedia(props) {
    const { url, className } = props;
    return (
        <ListItem>
            <Link to={url} target="_blank" rel="noopener noreferrer">
                <Italic className={className} />
            </Link>
        </ListItem>
    );
}
export default SocialMedia;
