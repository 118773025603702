import React from "react";

function NumberCard({ icon, number, label }) {
    return (
        <div className="col-md-3 col-sm-6">
            <div className="number">
                <i className={`fa ${icon}`}></i>
                <h3>{number}</h3>
                <span>{label}</span>
            </div>
        </div>
    );
}

export default NumberCard;
