import React from "react";

function PageNotFound() {
    return (
        <div className="vh-100 d-flex justify-content-center align-items-center">
            <div className="text-center">
                <h1 className="display-1 text-danger">404</h1>
                <p className="display-4">Oops! Page Not Found.</p>
                <p className="lead">
                    We can't find the page you're looking for. Click the button
                    below to return to the homepage.
                </p>
                <a href="/" className="btn btn-primary btn-lg">
                    Home Page
                </a>
            </div>
        </div>
    );
}

export default PageNotFound;
