import React from "react";
import { Link } from "react-router-dom";

function PostTitle({
    titleUrl = "#",
    titleName = "No title",
    classH3 = "",
    fontSize = 25,
}) {
    return (
        <h3 className={classH3}>
            <Link
                to={titleUrl}
                style={{
                    fontSize: `${fontSize}px`,
                    textDecoration: "none",
                    color: "black",
                    textAlign: "justify",
                    overflow: "hidden",
                    display: "block",
                    width: "100%",
                }}
            >
                {titleName}
            </Link>
        </h3>
    );
}
export default PostTitle;
