import React from "react";
import ListItem from "../../tags/ul/ListItem";

function FooterGallery(props) {
    return (
        <ListItem>
            <img src={props.url} alt="Gallery Item" onClick={props.onClick} />
        </ListItem>
    );
}
export default FooterGallery;
