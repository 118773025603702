import React, { useEffect } from "react";
import FooterCard from "../UI/FooterCard";
import Logo from "./Logo";
import UnorderedList from "../../tags/ul/UnorderedList";
import ListItem from "../../tags/ul/ListItem";
import Italic from "../../tags/Italic";
import Paragraph from "../../tags/Paragraph";
import useFetch, { getApiUrl } from "../../useFetch/useFetch";

function FirstColumn() {
    const { data: contact } = useFetch("get-contact");

    useEffect(() => {
        if (contact && contact.logo) {
            const link =
                document.querySelector("link[rel*='icon']") ||
                document.createElement("link");
            link.type = "image/x-icon";
            link.rel = "shortcut icon";
            link.href = getApiUrl() + contact.logo; // API'den gelen logo URL'ini favicon olarak ayarladık.
            document.getElementsByTagName("head")[0].appendChild(link);

            
        }
    }, [contact]);

    if (!contact) return null;

    return (
        <div className="col-md-4">
            <FooterCard className="footer">
                <Logo url={getApiUrl() + contact.logo} />
                <Paragraph text={contact.description} textAlign="justify" />

                <UnorderedList className="footer-contact list-unstyled">
                    {contact.address && (
                        <ListItem>
                            <Italic className={"fa fa-map-marker"} />
                            {contact.address}
                        </ListItem>
                    )}
                    {contact.phone && (
                        <ListItem>
                            <Italic className={"fa fa-phone"} />
                            {contact.phone}
                        </ListItem>
                    )}
                    {contact.email && (
                        <ListItem>
                            <Italic className={"fa fa-envelope"} />
                            {contact.email}
                        </ListItem>
                    )}
                </UnorderedList>
            </FooterCard>
        </div>
    );
}
export default FirstColumn;
