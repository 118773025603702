import React from "react";
import EventItem from "../../events/EventItem";
import HomeCard from "../HomeCard";
import useFetch, { getApiUrl } from "../../useFetch/useFetch";
import { formatDate } from "./../../useFetch/helpers";

function Events() {
    const { data: events } = useFetch("get-events");

    return (
        <div id="events" className="section">
            <div className="container">
                <div className="row">
                    {events && events.length > 0 && (
                        <div className="col-md-12 col-md-offset-2">
                            <HomeCard
                                title="Upcoming Events"
                                description={null}
                            />
                        </div>
                    )}

                    {events &&
                        events.map((event) => (
                            <EventItem
                                key={event.id}
                                imageUrl={null}
                                imageSrc={
                                    getApiUrl() +
                                    event.get_event_bg_image.file_url
                                }
                                titleUrl={`/events/event/${event.id}`}
                                titleName={event.title}
                                time={formatDate(event.start, event.end)}
                                location={event.location}
                                description={event.short_text}
                            />
                        ))}

                    {/* <div className="clearfix visible-md visible-lg"></div> */}
                </div>
            </div>
        </div>
    );
}
export default Events;
