import React from "react";
import Carousel from "react-bootstrap/Carousel";
import PrimaryAhref from "../../tags/button/PrimaryAhref";

function CarouselSlide({ captionTitle, captionText, button_url }) {
    return (
        <Carousel.Caption>
            <h3>{captionTitle}</h3>
            <p>{captionText}</p>

            {button_url && <PrimaryAhref text="Read more" href={button_url} />}
        </Carousel.Caption>
    );
}

export default CarouselSlide;
