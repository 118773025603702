import React from "react";
import UnorderedList from "../../tags/ul/UnorderedList";
import CategoryItem from "./CategoryItem";

function PostCategory(props) {
    const { categories, categoryType } = props;

    return (
        <div className="widget">
            <h3 className="widget-title">Category</h3>
            <div className="widget-category">
                <UnorderedList className="list-unstyled">
                    {categories &&
                        categories.map((category) => {
                            let categoryTotal = 0;

                            if (categoryType === "causes") {
                                categoryTotal =
                                    category.get_category_causes_count;
                            } else if (categoryType === "blogs") {
                                categoryTotal =
                                    category.get_category_blogs_count;
                            } else if (categoryType === "events") {
                                categoryTotal =
                                    category.get_category_events_count;
                            }

                            return (
                                <CategoryItem
                                    key={category.id}
                                    categoryUrl={`/category/${categoryType}/${category.id}`}
                                    name={category.name}
                                    total={categoryTotal}
                                />
                            );
                        })}
                </UnorderedList>
            </div>
        </div>
    );
}

export default PostCategory;
