import React from "react";
import RightSidebar from "../postInfo/rightSidebar/RightSidebar";
import PageHeader from "../header/PageHeader";
import ContentPage from "../postInfo/contentPage/ContentPage";
import MainPage from "../postInfo/contentPage/MainPage";
import CauseItem from "./CauseItem";
import useFetch, { getApiUrl } from "../useFetch/useFetch";
import { useParams } from "react-router-dom";
import IsNotAnyThing from "../website/IsNotAnyThing";

function CategoryCauses() {
    const { id } = useParams();
    const { data } = useFetch(`category-causes/` + id);
    let categories = null;
    let causes = null;

    let selectedCategory = "causes";

    if (data && data.category && data.category.name) {
        selectedCategory = data.category.name;
    }

    if (data && data.categories) {
        categories = data.categories;
    }

    if (data && data.causes) {
        causes = data.causes;
    }

    return (
        <div>
            <PageHeader title={`causes/${selectedCategory}`} />
            <ContentPage>
                <MainPage>
                    {causes && causes.length > 0 ? (
                        causes.map((cause) => (
                            <CauseItem
                                key={cause.id}
                                columnSize={6}
                                imageUrl={null}
                                imageSrc={
                                    getApiUrl() +
                                    cause.get_cause_bg_image.file_url
                                }
                                raised={null}
                                goal={cause.goal}
                                progres={null}
                                donateBtnStatus={cause.donate}
                                donateBarStatus={cause.donate_bar}
                                titleUrl={`/causes/cause/${cause.id}`}
                                titleName={cause.title}
                                description={cause.short_text}
                            />
                        ))
                    ) : (
                        <IsNotAnyThing />
                    )}
                </MainPage>
                <RightSidebar categories={categories} categoryType={"causes"} />
            </ContentPage>
        </div>
    );
}
export default CategoryCauses;
