import React from "react";
import PrimaryAhref from "../tags/button/PrimaryAhref";
import Paragraph from "../tags/Paragraph";
import AboutVideo from "../about/AboutVideo";
import useFetch from "../useFetch/useFetch";

function About() {
    const { data: about } = useFetch("get-short-about-us");
    if (!about) return null;
    return (
        <div id="about" className="section">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="section-title">
                            <h2 className="title">{about.title}</h2>
                        </div>
                        <div className="about-content">
                            <Paragraph
                                textAlign="justify"
                                text={about.short_text}
                            />
                            <PrimaryAhref text="Read More" href="/about" />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <AboutVideo video_link={about.video_link} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
