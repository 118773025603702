import React from "react";

function AboutVideo({ video_link }) {
    return (
        <iframe
            width="100%" // Genişlik değerini %100 yap
            height="100%" // Yükseklik değerini %100 yap
            src={video_link}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="about-video"
            title="About Video"
        ></iframe>
    );
}

export default AboutVideo;
