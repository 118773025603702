import React from "react";
import BlogItem from "./BlogItem";
import RightSidebar from "../postInfo/rightSidebar/RightSidebar";
import PageHeader from "../header/PageHeader";
import ContentPage from "../postInfo/contentPage/ContentPage";
import MainPage from "../postInfo/contentPage/MainPage";
import useFetch, { getApiUrl } from "../useFetch/useFetch";
import LoadingModalWithTimer from "../website/LoadingModalWithTimer";
import IsNotAnyThing from "../website/IsNotAnyThing";

function Blogs() {
    const { data } = useFetch("blogs");

    let categories = null;
    let blogs = null;

    if (data && data.categories) {
        categories = data.categories;
    }

    if (data && data.blogs) {
        blogs = data.blogs;
    }

    return (
        <div>
            <PageHeader title={"Blogs"} />
            <LoadingModalWithTimer timer={1000} />
            <ContentPage>
                <MainPage columnSize={9}>
                    {blogs && blogs.length > 0 ? (
                        blogs.map((blog) => (
                            <BlogItem
                                key={blog.id}
                                columnSize={6}
                                imageUrl={null}
                                imageSrc={
                                    getApiUrl() +
                                    blog.get_blog_bg_image.file_url
                                }
                                titleUrl={`/blogs/blog/${blog.id}`}
                                titleName={blog.title}
                                date={blog.created_at}
                                userName={null}
                                comments={null}
                                description={blog.short_text}
                            />
                        ))
                    ) : (
                        <IsNotAnyThing />
                    )}
                </MainPage>
                <RightSidebar categories={categories} categoryType={"blogs"} />
            </ContentPage>
        </div>
    );
}

export default Blogs;
