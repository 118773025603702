import React from "react";
import { Link } from "react-router-dom";
import ListItem from "../../tags/ul/ListItem";

function FooterMenu(props) {
    return (
        <ListItem>
            <Link style={{ textDecoration: "none" }} to={props.url}>
                {props.name}
            </Link>
        </ListItem>
    );
}
export default FooterMenu;
