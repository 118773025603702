export function RastgeleSayiOlusturma() {
    const min = 11111;
    const max = 99999;
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function formatDate(startDateStr, endDateStr) {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    const dateOptions = { year: "numeric", month: "2-digit", day: "2-digit" };
    const timeOptions = {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    };

    const startFormattedDate = startDate.toLocaleDateString(
        "en-US",
        dateOptions
    );
    const startFormattedTime = startDate.toLocaleTimeString(
        "en-US",
        timeOptions
    );

    const endFormattedDate = endDate.toLocaleDateString("en-US", dateOptions);
    const endFormattedTime = endDate.toLocaleTimeString("en-US", timeOptions);

    if (startFormattedDate === endFormattedDate) {
        return `${startFormattedDate} | ${startFormattedTime} - ${endFormattedTime}`;
    } else {
        return `${startFormattedDate} ${startFormattedTime} | ${endFormattedDate} ${endFormattedTime}`;
    }
}

export function formatDateToCustom(dateStr) {
    const date = new Date(dateStr);
    const options = { year: "numeric", month: "long", day: "2-digit" };
    return date.toLocaleDateString("en-GB", options);
}