import React from "react";
import RightSidebar from "./../../postInfo/rightSidebar/RightSidebar";
import PageHeader from "./../../header/PageHeader";
import ContentPage from "./../../postInfo/contentPage/ContentPage";
import MainPage from "./../../postInfo/contentPage/MainPage";
import { useParams } from "react-router-dom";
import useFetch, { getApiUrl } from "../../useFetch/useFetch";
import PostSlider from "../../postInfo/postSlider/PostSlider";
import PostContent from "../../postInfo/postContent/PostContent";
import LoadingModalWithTimer from "../../website/LoadingModalWithTimer";
import PostTitle from "../../postInfo/PostTitle";
import IsNotAnyThing from "../../website/IsNotAnyThing";
import PrimaryAhref from "../../tags/button/PrimaryAhref";
import PostAuthorInfo from "../../postInfo/PostAuthorInfo";

function SingleCause() {
    const { id } = useParams();
    const { data } = useFetch(`cause/` + id);
    let categories = null;
    let cause = null;

    let causeTitle = "No title";

    if (data && data.categories) {
        categories = data.categories;
    }

    if (data && data.cause) {
        cause = data.cause;
        causeTitle = cause.title;
    }

    const causeFiles =
        data && data.files
            ? data.files.map((file) => getApiUrl() + file.file_url)
            : [];

    return (
        <>
            <LoadingModalWithTimer timer={1000} />
            <PageHeader title={`causes/${causeTitle}`} />
            <ContentPage>
                <MainPage>
                    {data && data.cause !== null ? (
                        <>
                            <PostSlider images={causeFiles} />

                            {cause.donate_bar === "1" && (
                                <div className="clearfix mt-5">
                                    <div className="row align-items-center">
                                        <div className="col-md-8">
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    style={{ width: "53%" }}
                                                    aria-valuenow="53"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                >
                                                    53%
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between mt-2">
                                                <span className="causes-raised">
                                                    Raised:{" "}
                                                    <strong>52.000$</strong>
                                                </span>
                                                <span className="causes-goal">
                                                    Goal:{" "}
                                                    <strong>90.000$</strong>
                                                </span>
                                            </div>
                                        </div>
                                        {cause.donate === "1" && (
                                            <div className="col-md-4 text-md-right">
                                                <PrimaryAhref
                                                    text={"Donate Now"}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            <div className="mt-3">
                                {causeTitle && (
                                    <PostTitle
                                        titleName={causeTitle}
                                        fontSize={30}
                                    />
                                )}
                                <PostAuthorInfo created_at={cause.created_at} />
                            </div>
                            {cause && (
                                <PostContent content={cause.description} />
                            )}
                        </>
                    ) : (
                        <IsNotAnyThing />
                    )}
                </MainPage>

                <RightSidebar categories={categories} categoryType={"causes"} />
            </ContentPage>
        </>
    );
}
export default SingleCause;
