import React from "react";

function WidgetTag(props) {
    return (
        <div className="widget">
            <h3 className="widget-title">{props.title}</h3>

            {props.children}
        </div>
    );
}
export default WidgetTag;
