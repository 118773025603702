import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

const useFetch = (apiName) => {
    const url = getApiUrl() + "/api/" + apiName;
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(true);

    useEffect(() => {
        const abortCont = new AbortController();

        fetch(url, { signal: abortCont.signal })
            .then((res) => {
                if (!res.ok) {
                    // HTTP durum kodlarını kontrol edelim.
                    throw Error("Could not fetch the data for that resource");
                }
                return res.json();
            })
            .then((jsonResponse) => {
                if (jsonResponse.status) {
                    setData(jsonResponse.data);
                    setError(null);
                } else {
                    throw Error(jsonResponse.message);
                }
                setIsPending(false);
            })
            .catch((err) => {
                if (err.name !== "AbortError") {
                    setError(
                        err.message ||
                            "An unexpected error occurred. Please try again later."
                    );
                    setIsPending(false);
                }
            });

        return () => abortCont.abort();
    }, [url]);

    return { data, isPending, error };
};

export default useFetch;

export function getRandomInt() {
    const min = 1111111111;
    const max = 9999999999;
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getApiUrl() {
    return "https://panel.samaa.ngo";
    // return "https://samaa.ngo";
    // return "http://localhost:8000";
}

export function generateUniqueID() {
    return uuidv4();
}
