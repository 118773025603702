import React, { useState } from "react";
import ListItem from "../../tags/ul/ListItem";
import { Link } from "react-router-dom";
import LoadingModalWithTimer from "../../website/LoadingModalWithTimer";

function CategoryItem(props) {
    const { categoryUrl } = props;
    const [showModal, setShowModal] = useState(false);

    const handleClick = () => {
        setShowModal(true);

        setTimeout(() => {
            setShowModal(false);
        }, 500);
    };

    return (
        <ListItem>
            <Link
                to={categoryUrl}
                style={{ textDecoration: "none", color: "black" }}
                onClick={handleClick}
            >
                {props.name}
                <span>({props.total})</span>
            </Link>
            {showModal && <LoadingModalWithTimer timer={500} />}
        </ListItem>
    );
}
export default CategoryItem;
