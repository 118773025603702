import React from "react";

function IsNotAnyThing() {
    return (
        <div className="mt-0 d-flex justify-content-center align-items-center">
            <div className="text-center">
                <p className="display-4 text-danger">Oops!</p>
                <p className="lead">
                    We can't find the thing you're looking for.
                </p>
            </div>
        </div>
    );
}

export default IsNotAnyThing;
