import React from "react";
import BlogItem from "../../blogs/BlogItem";
import HomeCard from "../HomeCard";
import useFetch, { getApiUrl } from "../../useFetch/useFetch";

function Blogs() {
    const { data: blogs } = useFetch("get-blogs");

    return (
        <div id="blog" className="section">
            <div className="container">
                <div className="row">
                    {blogs && blogs.length > 0 && (
                        <div className="col-md-12 col-md-offset-2">
                            <HomeCard title="Our Blogs" description={null} />
                        </div>
                    )}

                    {blogs &&
                        blogs.map((blog) => (
                            <BlogItem
                                key={blog.id}
                                columnSize={6}
                                imageUrl={null}
                                imageSrc={
                                    getApiUrl() +
                                    blog.get_blog_bg_image.file_url
                                }
                                titleUrl={`/blogs/blog/${blog.id}`}
                                titleName={blog.title}
                                date={blog.created_at}
                                userName={null}
                                comments={null}
                                description={blog.short_text}
                            />
                        ))}
                </div>
            </div>
        </div>
    );
}
export default Blogs;
