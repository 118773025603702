import React, { forwardRef } from "react";

const LoadingModal = forwardRef(({ isPending = true, message = null }, ref) => {
    return (
        <>
            {isPending && (
                <div ref={ref} className="modal-background">
                    <div className="modal-content">
                        {message ? message : "Loading..."}
                    </div>
                    <style>{`
                        .modal-background {
                            position: fixed;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background: rgba(0, 0, 0, 0.5);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            z-index: 1000;
                        }
                        .modal-content {
                            width: 30vmin;
                            height: 30vmin;
                            padding: 20px;
                            background: white;
                            border-radius: 10px;
                            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    `}</style>
                </div>
            )}
        </>
    );
});

export default LoadingModal;
