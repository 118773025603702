import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes } from "react-router-dom";

import Navbar from "./navbar/Navbar";

import Footer from "./footer/Footer";
import CustomeRoutes from "./customeRoutes/CustomeRoutes";
import { DataProvider } from "../contexts/DataContext";

function App() {

    return (
        <DataProvider>
            <Router>
                <div className="App">
                    <Navbar />
                    <Routes>{CustomeRoutes}</Routes>

                    <Footer />
                </div>
            </Router>
        </DataProvider>
    );
}

export default App;
