import React from "react";

function Subscribe() {
    return (
        <form className="footer-newsletter">
            <input
                className="input"
                type="email"
                placeholder="Enter your email"
            ></input>
            <button className="primary-button">Subscribe</button>
        </form>
    );
}
export default Subscribe;
