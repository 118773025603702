import React from "react";
import FooterMenu from "./FooterMenu";
import UnorderedList from "../../tags/ul/UnorderedList";

function FooterBottom() {
    return (
        <div id="footer-bottom" className="row">
            <div className="col-md-6 order-md-2">
                <UnorderedList className="footer-nav ">
                    <FooterMenu url="/" name="Home" />
                    <FooterMenu url="/about" name="About" />
                    <FooterMenu url="/causes" name="Causes" />
                    <FooterMenu url="/events" name="Events" />
                    <FooterMenu url="/blogs" name="Blogs" />
                    <FooterMenu url="/contact" name="Contact" />
                </UnorderedList>
            </div>
            <div className="col-md-6 order-md-1">
                <div className="footer-copyright">
                    <span>
                        Copyright &copy; Samaa NGO &nbsp;
                        <i
                            className="fa fa-heart text-danger"
                            aria-hidden="true"
                        ></i>
                    </span>
                </div>
            </div>
        </div>
    );
}
export default FooterBottom;
