import React from "react";
import UnorderedList from "../../tags/ul/UnorderedList";
import ListItem from "../../tags/ul/ListItem";
import { Link } from "react-router-dom";

function LatestPostItem(props) {
    return (
        <div className="widget-post">
            <Link
                to={props.postUrl}
                style={{ textDecoration: "none", color: "black" }}
            >
                <div className="widget-img">
                    <img src={props.imageSrc} alt="s" />
                </div>
                <div
                    className="widget-content"
                    style={{ fontSize: "13px", textAlign: "justify" }}
                >
                    {props.content}
                </div>
            </Link>
            <UnorderedList className="article-meta list-unstyled">
                <ListItem>{props.userName}</ListItem>
                <ListItem>{props.date}</ListItem>
            </UnorderedList>
        </div>
    );
}
export default LatestPostItem;
