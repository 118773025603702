import React from "react";
import "./postSlider.css";

import Carousel from "react-bootstrap/Carousel";

function PostSlider(props) {
    return (
        <div className="post-image-to-slider">
            <Carousel>
                {props.images.map((image, index) => (
                    <Carousel.Item key={index}>
                        <img
                            className="d-block w-100"
                            src={image}
                            alt="Slide"
                        />
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    );
}

export default PostSlider;
