import React from "react";
import { Parallax } from "react-parallax";

function CtaImage(props) {
    const { imageSrc, imageSize } = props;

    return (
        <Parallax bgImage={imageSrc} strength={imageSize}>
            <div style={{ height: imageSize }}>{props.children}</div>
        </Parallax>
    );
}

export default CtaImage;
