import React from "react";
import { Link } from "react-router-dom";

function LatestCauseItem(props) {
    const { postUrl, imageSrc, title, progres, raised, goal, donateBarStatus } =
        props;

    return (
        <div className="widget-causes">
            <Link
                to={postUrl}
                style={{
                    fontSize: "13px",
                    textDecoration: "none",
                    textAlign: "justify",
                    color: "black",
                }}
            >
                <div className="widget-img">
                    <img src={imageSrc} alt="" />
                </div>
                <div className="widget-content">
                    {title}
                    {donateBarStatus === "1" && (
                        <div className="causes-progress">
                            <div className="causes-progress-bar">
                                <div style={{ width: `${progres}%` }}></div>
                            </div>
                        </div>
                    )}
                </div>
            </Link>
            {donateBarStatus === "1" && (
                <div>
                    <span className="causes-raised">
                        Raised: <strong>{raised}$</strong>
                    </span>
                    -
                    <span className="causes-goal">
                        Goal: <strong>{goal}$</strong>
                    </span>
                </div>
            )}
        </div>
    );
}
export default LatestCauseItem;
