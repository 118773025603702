import React from "react";
import { Route } from "react-router-dom";

import Home from "./../home/Home";
import About from "./../about/About";
import Causes from "./../causes/Causes";
import Events from "./../events/Events";
import Blogs from "./../blogs/Blogs";
import Contact from "../contact/Contact";
import SingleCause from "../causes/singleCause/SingleCause";
import SingleEvent from "../events/singleEvent/SingleEvent";
import SingleBlog from "../blogs/singleBlog/SingleBlog";
import PageNotFound from "./../website/PageNotFound";
import CategoryCauses from "../causes/CategoryCauses";
import CategoryBlogs from "../blogs/CategoryBlogs";
import CategoryEvents from "../events/CategoryEvents";

const CustomeRoutes = (
    <>
        <Route exact path="/" element={<Home />} />

        {/* About */}
        <Route path="/about" element={<About />} />

        {/* Cause */}
        <Route path="/causes" element={<Causes />} />
        <Route path="/category/causes/:id" element={<CategoryCauses />} />
        <Route path="/causes/cause/:id" element={<SingleCause />} />

        {/* Event */}
        <Route path="/events" element={<Events />} />
        <Route path="/category/events/:id" element={<CategoryEvents />} />
        <Route path="/events/event/:id" element={<SingleEvent />} />

        {/* Blog */}
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/category/blogs/:id" element={<CategoryBlogs />} />
        <Route path="/blogs/blog/:id" element={<SingleBlog />} />

        {/* Contact */}
        <Route path="/contact" element={<Contact />} />

        <Route path="*" element={<PageNotFound />} />
    </>
);

export default CustomeRoutes;
