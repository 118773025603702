import React from 'react'
import UnorderedList from '../tags/ul/UnorderedList';
import ListItem from '../tags/ul/ListItem';
import { formatDateToCustom } from '../useFetch/helpers';

function PostAuthorInfo(props) {
  return (
      <div>
          <UnorderedList className="article-meta list-unstyled">
              <ListItem>{formatDateToCustom(props.created_at)}</ListItem>
              <ListItem>{props.author}</ListItem>
              <ListItem>{props.comments}</ListItem>
          </UnorderedList>
      </div>
  );
}
export default PostAuthorInfo