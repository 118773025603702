import React from "react";

const HomeCard = ({title, description}) => {
    return (
        <div className="col-md-12 col-md-offset-2">
            <div className="section-title text-center">
                <h2 className="title">{title}</h2>
                <p className="sub-title">{description}</p>
            </div>
        </div>
    );
};
export default HomeCard;
