import React from "react";
import RightSidebar from "../postInfo/rightSidebar/RightSidebar";
import PageHeader from "../header/PageHeader";
import ContentPage from "../postInfo/contentPage/ContentPage";
import MainPage from "../postInfo/contentPage/MainPage";
import EventItem from "./EventItem";
import useFetch, { getApiUrl } from "../useFetch/useFetch";
import LoadingModalWithTimer from "../website/LoadingModalWithTimer";
import IsNotAnyThing from "../website/IsNotAnyThing";
import { formatDate } from "../useFetch/helpers";

function Events() {
    const { data } = useFetch("events");
    let categories = null;
    let events = null;

    if (data && data.categories) {
        categories = data.categories;
    }

    if (data && data.events) {
        events = data.events;
    }

    return (
        <div>
            <PageHeader title={"Events"} />
            <LoadingModalWithTimer timer={1000} />
            <ContentPage>
                <MainPage>
                    {events && events.length > 0 ? (
                        events.map((event) => (
                            <EventItem
                                key={event.id}
                                imageUrl={null}
                                imageSrc={
                                    getApiUrl() +
                                    event.get_event_bg_image.file_url
                                }
                                titleUrl={`/events/event/${event.id}`}
                                titleName={event.title}
                                time={formatDate(event.start, event.end)}
                                location={event.location}
                                description={event.short_text}
                            />
                        ))
                    ) : (
                        <IsNotAnyThing />
                    )}
                </MainPage>
                <RightSidebar categories={categories} categoryType={"events"} />
            </ContentPage>
        </div>
    );
}
export default Events;
