import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./BasicExample.css";
import { Link } from "react-router-dom";

function BasicExample() {
    const imageSrc = process.env.PUBLIC_URL + "/648159692b531.png";

    return (
        <Navbar expand="lg" className="bg-body-tertiary">
            <Container>
                <Navbar.Brand as={Link} to="/">
                    <img src={imageSrc} alt="logo" id="websiteLogo" />
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="w-100">
                    <Nav className="nav-right-align">
                        <Nav.Link as={Link} to="/">
                            Home
                        </Nav.Link>
                        <Nav.Link as={Link} to="/about">
                            About
                        </Nav.Link>
                        <Nav.Link as={Link} to="/causes">
                            Causes
                        </Nav.Link>
                        <Nav.Link as={Link} to="/events">
                            Events
                        </Nav.Link>
                        <Nav.Link as={Link} to="/blogs">
                            Blogs
                        </Nav.Link>
                        <Nav.Link as={Link} to="/contact">
                            Contact
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default BasicExample;
