import React from 'react'

function ContentPage(props) {
  return (
      <div className="section">
          <div className="container">
              <div className="row">{props.children}</div>
          </div>
      </div>
  );
}
export default ContentPage;