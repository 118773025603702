import React from "react";
import "./PrimaryButton.css";
import { Link } from "react-router-dom";

function PrimaryAhref({ text, href = "#" }) {
    return (
        <Link to={href} className="primary-button">
            {text}
        </Link>
    );
}

export default PrimaryAhref;
