import React from "react";
import RightSidebar from "../postInfo/rightSidebar/RightSidebar";
import PageHeader from "../header/PageHeader";
import ContentPage from "../postInfo/contentPage/ContentPage";
import MainPage from "../postInfo/contentPage/MainPage";
import useFetch, { getApiUrl } from "../useFetch/useFetch";
import { useParams } from "react-router-dom";
import IsNotAnyThing from "../website/IsNotAnyThing";
import EventItem from "./EventItem";
import { formatDate } from "../useFetch/helpers";


function CategoryEvents() {
    const { id } = useParams();
    const { data } = useFetch(`category-events/` + id);
    let categories = null;
    let events = null;

    let selectedCategory = "events";

    if (data && data.category && data.category.name) {
        selectedCategory = data.category.name;
    }

    if (data && data.categories) {
        categories = data.categories;
    }

    if (data && data.events) {
        events = data.events;
    }

    return (
        <div>
            <PageHeader title={`events/${selectedCategory}`} />
            <ContentPage>
                <MainPage>
                    {events && events.length > 0 ? (
                        events.map((event) => (
                            <EventItem
                                key={event.id}
                                imageUrl={null}
                                imageSrc={
                                    getApiUrl() +
                                    event.get_event_bg_image.file_url
                                }
                                titleUrl={`/events/event/${event.id}`}
                                titleName={event.title}
                                time={formatDate(event.start, event.end)}
                                location={event.location}
                                description={event.short_text}
                            />
                        ))
                    ) : (
                        <IsNotAnyThing />
                    )}
                </MainPage>
                <RightSidebar categories={categories} categoryType={"events"} />
            </ContentPage>
        </div>
    );
}
export default CategoryEvents;
