import React from "react";
import UnorderedList from "../tags/ul/UnorderedList";
import ListItem from "../tags/ul/ListItem";
import Paragraph from "../tags/Paragraph";
import PostTitle from "../postInfo/PostTitle";
import { Link } from "react-router-dom";
import { formatDateToCustom } from "../useFetch/helpers";

function BlogItem(props) {
    const { columnSize } = props;

    return (
        <div className={"col-md-" + columnSize}>
            <div className="article">
                <div className="article-img">
                    <Link to={props.titleUrl}>
                        <img src={props.imageSrc} alt="" />
                    </Link>
                </div>
                <div className="article-content">
                    <PostTitle
                        classH3="article-title"
                        titleUrl={props.titleUrl}
                        titleName={props.titleName}
                        fontSize={20}
                    />

                    <UnorderedList className="article-meta list-unstyled">
                        <ListItem>{formatDateToCustom(props.date)}</ListItem>
                        <ListItem>{props.userName}</ListItem>
                        <ListItem>{props.comments}</ListItem>
                    </UnorderedList>
                    <Paragraph textAlign="justify" text={props.description} />
                </div>
            </div>
        </div>
    );
}
export default BlogItem;
