import React from "react";
import Slider from "./sliders/Slider";
import About from "./About";
import Number from "./numbers/Number";
import Causes from "./causes/Causes";
import CTA from "./CTA";
import Events from "./events/Events";
// import Testimonial from "./testimonial/Testimonial";
import Blogs from "./blogs/Blogs";
import LoadingModalWithTimer from "../website/LoadingModalWithTimer";

function Home() {

    return (
        <div>
            <LoadingModalWithTimer />

            <Slider />
            <About />
            <Number />
            <Causes />
            <CTA />
            <Events />
            {/* <Testimonial /> */}
            <Blogs />
        </div>
    );
}
export default Home;
