import React from 'react'
import { Link } from 'react-router-dom';

function EventImage(props) {
    const { imageUrl, imageSrc } = props;
  return (
      <div className="event-img">
          <Link to={imageUrl}>
              <img src={imageSrc} alt="" />
          </Link>
      </div>
  );
}
export default EventImage;