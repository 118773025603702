import React, { useState } from "react";
import FooterCard from "../UI/FooterCard";
import FooterTitle from "../UI/FooterTitle";
import UnorderedList from "../../tags/ul/UnorderedList";
import useFetch, { getApiUrl } from "../../useFetch/useFetch";
import ImageModal from "./ImageModal";
import FooterGallery from "./FooterGaller";

function SecondColumn() {
    const { data: galleries } = useFetch("get-galleries");
    const [currentImageIndex, setCurrentImageIndex] = useState(null);

    const openImage = (index) => {
        setCurrentImageIndex(index);
    };

    const closeImage = () => {
        setCurrentImageIndex(null);
    };

    const nextImage = () => {
        if (currentImageIndex < galleries.length - 1) {
            setCurrentImageIndex(currentImageIndex + 1);
        }
    };

    const prevImage = () => {
        if (currentImageIndex > 0) {
            setCurrentImageIndex(currentImageIndex - 1);
        }
    };

    return (
        <div className="col-md-4">
            <FooterCard className="footer">
                <FooterTitle title="Gallery" />
                <UnorderedList className="footer-galery list-unstyled">
                    {galleries &&
                        galleries.map((gallery, index) => (
                            <FooterGallery
                                key={gallery.id}
                                url={
                                    getApiUrl() +
                                    gallery.get_gallery_image.file_url
                                }
                                onClick={() => openImage(index)}
                            />
                        ))}
                </UnorderedList>
            </FooterCard>

            {galleries && currentImageIndex !== null && (
                <ImageModal
                    isOpen={currentImageIndex !== null}
                    imageUrl={
                        getApiUrl() +
                        galleries[currentImageIndex].get_gallery_image.file_url
                    }
                    onClose={closeImage}
                    onNext={nextImage}
                    onPrevious={prevImage}
                />
            )}
        </div>
    );
}
export default SecondColumn;
