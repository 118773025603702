import React from "react";
import PagaNavication from "./PageNavication";
import useFetch, { getApiUrl } from "../useFetch/useFetch";

function PageHeader({ title }) {
    const { data: joinUs } = useFetch("get-join-us");
    if (!joinUs) return null;
    const imageSrc = getApiUrl() + joinUs.image;

    return (
        <header>
            <div id="page-header" className="mt-0">
                <div
                    className="section-bg"
                    style={{ backgroundImage: `url(${imageSrc})` }}
                ></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="header-content">
                                <h1>{createTitle(title)}</h1>
                                <PagaNavication
                                    className="breadcrumb list-unstyled"
                                    items={createItems(title)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

function createTitle(title) {
    const parts = title.split("/");
    return capitalize(parts[parts.length - 1]);
}

function capitalize(text) {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

function createItems(title) {
    const parts = title.split("/");
    const items = [
        { name: "Home", url: "/" },
        { name: capitalize(parts[0]), url: `/${parts[0].toLowerCase()}` },
    ];

    if (parts.length > 1) {
        items.push({ name: createTitle(title), url: "" });
    }

    return items;
}

export default PageHeader;
