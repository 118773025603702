import React from "react";
import RightSidebar from "./../../postInfo/rightSidebar/RightSidebar";
import PageHeader from "./../../header/PageHeader";
import ContentPage from "./../../postInfo/contentPage/ContentPage";
import MainPage from "./../../postInfo/contentPage/MainPage";
import { useParams } from "react-router-dom";
import useFetch, { getApiUrl } from "../../useFetch/useFetch";
import LoadingModalWithTimer from "../../website/LoadingModalWithTimer";
import PostContent from "../../postInfo/postContent/PostContent";
import PostSlider from "../../postInfo/postSlider/PostSlider";
import PostTitle from "../../postInfo/PostTitle";
import IsNotAnyThing from "../../website/IsNotAnyThing";
import UnorderedList from "../../tags/ul/UnorderedList";
import ListItem from "../../tags/ul/ListItem";
import Italic from "../../tags/Italic";
import { formatDate } from "../../useFetch/helpers";
import PostAuthorInfo from "../../postInfo/PostAuthorInfo";

function SingleEvent() {
    const { id } = useParams();
    const { data } = useFetch(`event/` + id);
    let categories = null;
    let event = null;

    let eventTitle = "No title";

    if (data && data.categories) {
        categories = data.categories;
    }

    if (data && data.event) {
        event = data.event;
        eventTitle = event.title;
    }

    const eventFiles =
        data && data.files
            ? data.files.map((file) => getApiUrl() + file.file_url)
            : [];

    return (
        <>
            <LoadingModalWithTimer timer={1000} />
            <PageHeader title={`Events/${eventTitle}`} />
            <ContentPage>
                <MainPage>
                    {data && data.event !== null ? (
                        <>
                            <PostSlider images={eventFiles} />

                            <div className="mt-3">
                                {eventTitle && (
                                    <PostTitle
                                        titleName={eventTitle}
                                        fontSize={30}
                                    />
                                )}
                                <PostAuthorInfo created_at={event.created_at} />
                            </div>

                            {event && (
                                <PostContent content={event.description} />
                            )}

                            <div className="event-details">
                                <UnorderedList
                                    className={"event-meta list-unstyled"}
                                >
                                    <ListItem>
                                        <h4>Event Details</h4>
                                    </ListItem>
                                    <ListItem>
                                        <Italic className={"fa fa-clock-o"} />
                                        {formatDate(event.start, event.end)}
                                    </ListItem>
                                    <ListItem>
                                        <Italic
                                            className={"fa fa-map-marker"}
                                        />
                                        {event.location}
                                    </ListItem>
                                    {/* <ListItem>
                                        <Italic className={"fa fa-ticket"} />
                                    </ListItem> */}
                                </UnorderedList>
                            </div>
                        </>
                    ) : (
                        <IsNotAnyThing />
                    )}
                </MainPage>
                <RightSidebar categories={categories} categoryType={"events"} />
            </ContentPage>
        </>
    );
}
export default SingleEvent;
