import React from "react";
import NumberCard from "./NumberCard";
import useFetch from "../../useFetch/useFetch";

function Number() {
    const { data: numbers } = useFetch("get-about-info");

    return (
        <div id="numbers" className="section">
            <div className="container">
                <div className="row">
                    {numbers &&
                        numbers.map((number) => (
                            <NumberCard
                                key={number.id}
                                icon={number.class_name}
                                number={number.number}
                                label={number.desc}
                            />
                        ))}
                </div>
            </div>
        </div>
    );
}

export default Number;
