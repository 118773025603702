import React from "react";
import PageHeader from "../header/PageHeader";
import ContentPage from "../postInfo/contentPage/ContentPage";
import MainPage from "../postInfo/contentPage/MainPage";
import PostTitle from "./../postInfo/PostTitle";
import useFetch, { getApiUrl } from "../useFetch/useFetch";
import DOMPurify from "dompurify";
import LoadingModalWithTimer from "../website/LoadingModalWithTimer";
import PostSlider from "../postInfo/postSlider/PostSlider";

function About() {
    const { data } = useFetch("get-about-us-full");

    // Eğer data yoksa veya data içinde about yoksa hemen çıkış yap
    if (!data || !data.about) return null;

    const about = data.about;

    const cleanContent = DOMPurify.sanitize(about.description);

    const aboutFiles =
        data && data.files
            ? data.files.map((file) => getApiUrl() + file.file_url)
            : [];

    return (
        <div>
            <LoadingModalWithTimer timer={1000} />
            <PageHeader title={"About"} />
            <ContentPage>
                <PostTitle titleName={about.title} fontSize={30} />
                {aboutFiles && aboutFiles.length > 0 && (
                    <PostSlider images={aboutFiles} />
                )}
                <div className="mt-4"></div>
                <MainPage columnSize={12}>
                    
                    <div dangerouslySetInnerHTML={{ __html: cleanContent }} />
                </MainPage>
            </ContentPage>
        </div>
    );
}
export default About;
