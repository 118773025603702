import React from "react";
import { Link } from "react-router-dom";

function PagaNavication({ className, items }) {
    return (
        <ul className={className}>
            {items.map((item, index) => (
                <React.Fragment key={index}>
                    <ListItem
                        name={item.name}
                        url={item.url}
                        isActive={index === items.length - 1}
                    />
                    {index !== items.length - 1 && (
                        <span style={{ color: "white" }}> &nbsp;/&nbsp; </span>
                    )}
                </React.Fragment>
            ))}
        </ul>
    );
}

function ListItem({ name, url, isActive }) {
    const style = isActive
        ? { color: "green", textDecoration: "none" }
        : { textDecoration: "none" };

    return (
        <li>
            <Link to={url} style={style}>
                {name}
            </Link>
        </li>
    );
}

export default PagaNavication;
