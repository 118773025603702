import React from "react";
import FooterCard from "../UI/FooterCard";
import FooterTitle from "../UI/FooterTitle";
import Subscribe from "./Subscribe";
import SocialMedia from "./SocialMedia";

import UnorderedList from "../../tags/ul/UnorderedList";
import Paragraph from "../../tags/Paragraph";
import useFetch from "../../useFetch/useFetch";

function ThirdColumn() {
    const { data: socialMedia } = useFetch("get-social-media");

    return (
        <div className="col-md-4">
            <FooterCard className="footer">
                <FooterTitle title="Newsletter" />
                <Paragraph
                    text={
                        "Stay updated with the latest news much more by joining our newsletter! To be the first to hear about our updates, please enter your email address below and click the 'Subscribe' button."
                    }
                    textAlign="justify"
                />

                <Subscribe />
                <UnorderedList className="footer-social">
                    {socialMedia &&
                        socialMedia.map((media) => (
                            <SocialMedia
                                key={media.id}
                                url={media.link}
                                className={media.class_name}
                            />
                        ))}
                </UnorderedList>
            </FooterCard>
        </div>
    );
}
export default ThirdColumn;
