import React from "react";
import EventImage from "./EventImage";
import PostTitle from "../postInfo/PostTitle";
import UnorderedList from "../tags/ul/UnorderedList";
import ListItem from "../tags/ul/ListItem";
import Italic from "../tags/Italic";
import Paragraph from "../tags/Paragraph";

function EventItem(props) {
    return (
        <div className="col-md-6">
            <div className="event">
                <EventImage
                    imageUrl={props.imageSrc}
                    imageSrc={props.imageSrc}
                />
                <div className="event-content">
                    <PostTitle
                        titleUrl={props.titleUrl}
                        titleName={props.titleName}
                        fontSize={20}
                        classH3={"justify"}
                    />
                    <UnorderedList className="event-meta list-unstyled">
                        <ListItem>
                            <Italic className={"fa fa-clock-o"} />
                            {props.time}
                        </ListItem>
                        <ListItem>
                            <Italic className={"fa fa-map-marker"} />
                            {props.location}
                        </ListItem>
                    </UnorderedList>
                </div>
                <Paragraph text={props.description} textAlign="justify" />
            </div>
        </div>
    );
}
export default EventItem;
