import React from "react";

function Logo(props) {
    return (
        <div className="footer-logo">
            <div className="logo" href="#">
                <img src={props.url} alt="logo" />
            </div>
        </div>
    );
}
export default Logo;
