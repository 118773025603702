import React from "react";
import CtaImage from "./CtaImage";
import useFetch, { getApiUrl } from "../useFetch/useFetch";

function CTA() {
    const { data: joinUs } = useFetch("get-join-us");

    if (!joinUs) return null;

    return (
        <div id="cta" className="section">
            <CtaImage imageSrc={getApiUrl() + joinUs.image} imageSize={350}>
                <div className="container h-100">
                    <div className="row h-100 align-items-center">
                        <div className="col-md-12">
                            <div className="cta-content text-center">
                                <h1>{joinUs.title}</h1>
                                <p className="lead">{joinUs.description}</p>
                                <a
                                    href={getApiUrl()}
                                    className="primary-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Join us
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </CtaImage>
        </div>
    );
}
export default CTA;
