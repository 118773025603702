import React from "react";
import LoadingModal from "./LoadingModal";
import { useState } from "react";

function LoadingModalWithTimer({ timer = 1000 }) {
    const [isPending, setIsPending] = useState(true);
    const [message] = useState(null);

    setTimeout(() => {
        setIsPending(false);
    }, timer);

    return <LoadingModal isPending={isPending} message={message} />;
}
export default LoadingModalWithTimer;
