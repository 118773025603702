import React from "react";
import LatestPosts from "../latestPosts/LatestPosts";
import PostCategory from "../postCategory/PostCategory";

function RightSidebar(props) {
    const { categories, categoryType } = props;

    return (
        <aside id="aside" className="col-md-3">
            <PostCategory categories={categories} categoryType={categoryType} />

            <LatestPosts />
        </aside>
    );
}
export default RightSidebar;
