import React from "react";
import LatestPostItem from "./LatestPostItem";
import LatestCauseItem from "./LatestCauseItem";
import WidgetTag from "./WidgetTag";
import useFetch, { getApiUrl } from "../../useFetch/useFetch";
import { formatDateToCustom } from "../../useFetch/helpers";

function LatestPosts() {
    const { data } = useFetch("get-latest-posts");

    const causes = data?.causes;
    const blogs = data?.blogs;

    return (
        <>
            {causes?.length > 0 && (
                <WidgetTag title={"Latest Causes"}>
                    {causes.map((cause) => (
                        <LatestCauseItem
                            key={cause.id}
                            postUrl={`/causes/cause/${cause.id}`}
                            imageSrc={
                                getApiUrl() + cause.get_cause_bg_image.file_url
                            }
                            title={cause.title}
                            donateBarStatus={cause.donate_bar}
                            progres={53}
                            raised={45000}
                            goal={5000}
                        />
                    ))}
                </WidgetTag>
            )}

            {blogs?.length > 0 && (
                <WidgetTag title={"Latest Posts"}>
                    {blogs.map((blog) => (
                        <LatestPostItem
                            key={blog.id}
                            postUrl={`/blogs/blog/${blog.id}`}
                            imageSrc={
                                getApiUrl() + blog.get_blog_bg_image.file_url
                            }
                            content={blog.title}
                            userName={null}
                            date={formatDateToCustom(blog.created_at)}
                        />
                    ))}
                </WidgetTag>
            )}
        </>
    );
}

export default LatestPosts;
