import React from "react";
import Paragraph from "../tags/Paragraph";
import PostTitle from "../postInfo/PostTitle";
import { Link } from "react-router-dom";
import PrimaryAhref from "../tags/button/PrimaryAhref";

function CauseItem(props) {
    const {
        columnSize,
        imageUrl,
        imageSrc,
        raised,
        goal,
        progres,
        donateBtnStatus = null,
        donateBarStatus,
        titleUrl,
        titleName,
        description,
    } = props;


    return (
        <div className={`col-md-${columnSize}`}>
            <div className="causes">
                <div className="causes-img">
                    <Link to={imageUrl}>
                        <img src={imageSrc} alt="" />
                    </Link>
                </div>
                {donateBarStatus === "1" && (
                    <div className="causes-progress">
                        <div className="causes-progress-bar">
                            <div style={{ width: `${progres}%` }}>
                                <span>{raised}%</span>
                            </div>
                        </div>
                        <div>
                            <span className="causes-raised">
                                Raised: <strong>{raised}$</strong>
                            </span>
                            <span className="causes-goal">
                                Goal: <strong>{goal}$</strong>
                            </span>
                        </div>
                    </div>
                )}

                <div className="causes-content">
                    <PostTitle
                        titleUrl={titleUrl}
                        titleName={titleName}
                        fontSize={20}
                    />

                    <Paragraph text={description} textAlign="justify" />

                    {donateBtnStatus === "1" && (
                        <PrimaryAhref text={"Donate"} />
                    )}
                </div>
            </div>
        </div>
    );
}

export default CauseItem;
