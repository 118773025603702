import React from "react";
import CauseItem from "../../causes/CauseItem";
import HomeCard from "../HomeCard";
import useFetch, { getApiUrl } from "../../useFetch/useFetch";

function Causes() {
    const { data: causes } = useFetch("get-causes");

    return (
        <div id="causes" className="section">
            <div className="container">
                <div className="row">
                    {causes && causes.length > 0 && (
                        <div className="col-md-12 col-md-offset-2">
                            <HomeCard title="Our Causes" description={null} />
                        </div>
                    )}

                    {causes &&
                        causes.map((cause) => (
                            <CauseItem
                                key={cause.id}
                                columnSize={6}
                                imageUrl={null}
                                imageSrc={
                                    getApiUrl() +
                                    cause.get_cause_bg_image.file_url
                                }
                                raised={null}
                                goal={cause.goal}
                                progres={null}
                                donateBtnStatus={cause.donate}
                                donateBarStatus={cause.donate_bar}
                                titleUrl={`/causes/cause/${cause.id}`}
                                titleName={cause.title}
                                description={cause.short_text}
                            />
                        ))}
                </div>
            </div>
        </div>
    );
}
export default Causes;
