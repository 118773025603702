import React, { createContext, useContext, useState, useEffect } from "react";

const DataContext = createContext();

export const useData = () => {
    return useContext(DataContext);
};

export const DataProvider = ({ children }) => {
    const [cachedData, setCachedData] = useState(null);
    const [lastUpdated, setLastUpdated] = useState(null);

    const fetchData = async () => {
        // Veriyi API'den alın
        const response = await fetch("YOUR_API_URL");
        const data = await response.json();

        setCachedData(data);
        setLastUpdated(Date.now());
    };

    useEffect(() => {
        const checkCacheValidity = () => {
            const FIVE_MINUTES = 5 * 60 * 1000;
            if (!lastUpdated || Date.now() - lastUpdated > FIVE_MINUTES) {
                fetchData();
            }
        };

        const interval = setInterval(checkCacheValidity, 5 * 60 * 1000);

        return () => {
            clearInterval(interval);
        };
    }, [lastUpdated]);

    return (
        <DataContext.Provider value={{ cachedData, fetchData }}>
            {children}
        </DataContext.Provider>
    );
};
