import React from "react";
import "./ImageModal.css";

function ImageModal({ isOpen, onClose, imageUrl, onNext, onPrevious }) {
    if (!isOpen) return null;

    return (
        <div className="image-modal">
            <span className="close" onClick={onClose}>
                &times;
            </span>
            <img src={imageUrl} alt="FullScreenImage" />
            <span className="previous" onClick={onPrevious}>
                &lt;
            </span>
            <span className="next" onClick={onNext}>
                &gt;
            </span>
        </div>
    );
}
export default ImageModal;
